<template>
  <div class="fill-height pa-0 ">
    <v-fade-transition mode="out-in">
      <MainMobileMenu @openSupportChat="openSupportChat" v-if="isMobile"/>
      <div class="overlayNav" v-if="isMobile && isOverlay ">
        <v-app-bar elevation="0" color="transparent" class="d-flex justify-end" fixed>
          <img src="../assets/icons/BackButten.svg" alt="" @click="backRouter" id="backOverlay">
        </v-app-bar>
      </div>
    </v-fade-transition>
    <div class="cartIcon d-flex" :class="!$vuetify.rtl ? 'rtl_cartIcon' : 'ltr_cartIcon'"
         v-if="!isMobile">
      <router-link :to="{name:'basket'}" v-if="!isMobile">
        <div>
          <v-btn x-small fab elevation="0" color="transparent">
            <img src="../assets/icons/backet.png" width="20px" alt="">
          </v-btn>
        </div>
        <div>
          <img src="../assets/notifyCartAssetes.svg" v-if="cartLength != 0" class="havePackage" alt="">
        </div>
      </router-link>
<!--      <div @click="openSupportChat" class="mr-1">-->
<!--        <v-btn x-small fab elevation="0" color="transparent">-->
<!--          <v-icon>mdi-message-outline</v-icon>-->
<!--        </v-btn>-->
<!--      </div>-->
    </div>
    <v-fade-transition>
      <div class="SupportMessenger" v-if="showMessenger">
        <SupportChatSidebar @closeSupportChatSidebar="closeSupportChatSidebar"/>
      </div>
    </v-fade-transition>
    <div class="fill-height pa-0 " :class="!isMobile && 'row'">
      <v-col cols="12" lg="1" class="pa-0" v-if="!isMobile">
        <div class="fill-height sidebarArea">
          <MainSidebar/>
        </div>
      </v-col>
      <v-col cols="12" md="12" style="background: white !important;" :class="!isMobile && 'pr-10'"
             lg="11">
        <div class="routerArea  fill-height d-flex flex-column mt-13" style="position: relative">
          <div class="routerInfo  text-right" :style="$vuetify.rtl && 'margin-left: 8%'"
               v-if="!isMobile">
            <div class="welcomeMessage " :class="!$vuetify.rtl && 'text-left'">
              {{ $t('welcome') }}
            </div>
            <div class="routeName " :class="!$vuetify.rtl && 'text-left'">
              <template v-if="!$route.meta.patentRoute">
                {{ $t(`routes.${$route.meta.routeKey}`) }}
              </template>
              <template v-else>
                <div class="panelRoute d-flex justify-start ">
                  <div class="parentRoute font-weight-bold">
                    <router-link :to="{name:'dashboard'}">
                      {{ $t(`routes.${$route.meta.patentRoute}`) }}
                    </router-link>
                  </div>
                  <div style="transform: rotate(180deg)" class=" parentRoute">
                    <v-icon>
                      mdi-menu-right
                    </v-icon>
                  </div>
                  <div>
                    {{ $t(`routes.${$route.meta.routeKey}`) }}
                  </div>
                  <div style="transform: rotate(180deg)" class="mx-2 parentRoute">
                  </div>

                </div>
              </template>
            </div>
          </div>
          <div class="routerBody flex-grow-1" id="routerArea" :class="{'overlayRoute':isOverlay}">
            <v-fade-transition mode="out-in">
              <router-view/>
            </v-fade-transition>
          </div>
          <div class="footer mt-5 pb-5"
               :class="{'rtl_footer':!$vuetify.rtl,'ltr_footer':$vuetify.rtl}"
               v-if="!isOverlay">
            <v-divider style="background: #f8f9fb"></v-divider>
            <div class="footerItems mt-5 d-flex justify-end">
              <div class="footerItem">
                copyright 2022 {{ getDomainPlatform() }} All Rights Reserved ©
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import MainSidebar from "@/components/Sidebar/MainSidebar";
import MainMobileMenu from "@/components/Navbar/MainMobileMenu";
import {mapGetters} from "vuex";
import SupportChatSidebar from "@/components/Messeges/SupportChatSidebar";
/*eslint-disable*/
export default {
  components: {SupportChatSidebar, MainMobileMenu, MainSidebar},
  data() {
    return {
      showMessenger: false,
      isMobile: true,
    }
  },
  methods: {
    openSupportChat() {
      this.$router.push({name: "chat", params: {userId: '1189'}})
      // this.showMessenger = true
    },
    closeSupportChatSidebar() {
      this.showMessenger = false
    },
    backRouter() {
      switch (this.$route.name) {
        case "chat":
          this.$router.push({name: 'messenger'})
          this.$emitEvent('changeWindow', 'contact')
          break;
        case "messenger":
          this.$router.push({name: 'dashboard'})
          break;
        default:
          this.$router.back()
          break
      }
    },
    prepareSize() {
      const windowWidth = window.innerWidth
      this.isMobile = windowWidth < 1500
    }
  },
  computed: {
    ...mapGetters({
      cart: 'getCart'
    }),

    cartLength() {
      if (this.cart) {
        return Object.keys(this.cart.items).length
      } else {
        return 0;
      }
    }
  },
  mounted() {
    this.$onEvent('openChatSupport', () => {
      this.openSupportChat();
    })
    this.prepareSize()
    window.addEventListener('resize', this.prepareSize);
  },
}
</script>

<style >
body {
  overflow: visible;
}

@media (min-width: 200px) and (max-width: 600px) {
  .routerBody {
    margin-right: 0px !important;
    height: auto !important;
    min-height: 100vh !important;
    overflow-y: visible !important;
    overflow-x: visible !important;
  }

  .overlayNav {
    margin-bottom: -50px;
  }

  .routerArea {
    height: auto !important;
    margin-top: 60px !important;
  }

  .snackBarBasket {
    position: fixed !important;
    width: 70% !important;
    bottom: 100px !important;
    /*left: 10% !important;*/
    right: 15% !important;
  }

  .rtl_routeBody {
    margin-right: 0% !important;
  }

  .ltr_routeBody {
    margin-left: 0% !important;
  }

  .SupportMessenger {
    top: 0px !important;
    bottom: 10px !important;
    left: 0px !important;
    right: 0px !important;
    width: 100% !important;
  }
}

.SupportMessenger {
  width: 500px;
  z-index: 9999;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px
}

.cartIcon {
  position: fixed;
  top: 15px;
  z-index: 9999;
}

.rtl_cartIcon {
  right: 15px;
}

.ltr_cartIcon {
  left: 15px;
}

.havePackage {
  position: absolute;
  top: -5px;
  right: -5px;
}

.footerItem {
  color: #1c53c1;
  font-size: 12px;
}

.footer {
  /*margin-right: 12%;*/
}

.rtl_footer {
  margin-right: 12%;
}

.ltr_footer {
  margin-left: 5%;
}

.routerBody {
  /*height: 85%;*/
  /*height: 100%;*/
  margin-top: 10px;
  background: linear-gradient(90deg, white 21px, transparent 0%) center,
  linear-gradient(#ffffff 21px, transparent 0%) center,
  #e2d9f7;
  background-size: 23px 23px;
  /*overflow-y: auto;*/
  /*overflow-x: hidden;*/
}

.rtl_routeBody {
  margin-right: 12%;
}

.ltr_routeBody {
  margin-left: 5%;
}

.welcomeMessage {
  color: #6d6d6d;
  font-size: 14px;
}

.routeName {
  font-size: 17px;
  color: #000;
  margin-top: 5px;
}

.routerArea {
  height: 100vh;
}

.sidebarArea {
  background: #f8f9fb;
  border-radius: 0px 30px 30px 0px !important;
  margin-top: 10px;
  height: 99%;
}

.parentRoute {
  color: #46b1a1;
  text-decoration: none;
}

a {
  text-decoration: none;
}

.snackBarBasket {
  position: absolute;
  bottom: 50px;
  width: 350px;
  height: 50px;
  background: #4576d8;
  right: calc(50% - 125px);
  border-radius: 15px;
  padding: 10px;
}

.priceBox {
  width: 35px;
  border-radius: 10px;
  background: white;
}

.overlayRoute {
  /*padding-top: 100px;*/
}
</style>

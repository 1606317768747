<template>
  <div>
    <div class="tableBox mt-8">
      <v-data-table
          disable-pagination
          hide-default-footer
          :items="users"
          :item-class="getItemClass"
          :headers="headers"
      >
        <template v-slot:item.id="{item}">
          {{ item.id }}
        </template>
        <template v-slot:item.payload="{item}">
          {{ item.payload?.source }}
          <br>
          {{ item.payload?.medium }}
        </template>
        <template v-slot:item.created_at="{item}">
          {{ item.created_at | dateFilter }}
        </template>
        <template v-slot:item.full_name="{item}">
          <template v-if="item.full_name == ' '">
            بدون نام
          </template>
          <template v-else>
            {{ item.full_name }}
          </template>
        </template>
        <template v-slot:item.first_purchase_date="{item}">
          <template v-if="item.first_purchase_date">
            {{ item.first_purchase_date | dateFilter }}
          </template>
          <template v-else class="">
            <v-btn color="grey" class="white--text font-weight-bold" x-small elevation="0">
              بدون خرید
            </v-btn>
          </template>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import momentJalali from "moment-jalaali";
/*eslint-disable*/
export default {
  data() {
    return {
      headers: [
        {text: 'ردیف', value: 'id', sortable: false, align: 'center'},
        {text: 'ایمیل', value: 'email', sortable: false, align: 'center'},
        {text: 'تاریخ اولین خرید', value: 'first_purchase_date', sortable: false, align: 'center'},
        {text: 'منبع', value: 'payload', sortable: false, align: 'center'},
        {text: 'تاریخ ثبت نام', value: 'created_at', sortable: false, align: 'center'},
      ],
      users: [],
    }
  },
  mounted() {
    this.$emitEvent('setLoading', true)
    this.requesting('user', 'getInvitees').then((resp) => {
      this.users = resp.data
      this.users.forEach((item, i) => {
        item.id = i + 1
        item.payload = JSON.parse(item.payload)
      })

    }).finally(() => {
      this.$emitEvent('setLoading', false)
    })
  },
  methods: {
    getItemClass(item) {
      if (item.first_purchase_date) {
        return 'withBuyRecord';
      }
    },
  },
  filters: {
    dateFilter(val) {
      if (!val) {
        return val;
      }
      return momentJalali(val).format('HH:mm jYYYY/jMM/jDD')
    }
  }
}
</script>

<style scoped>


.tableBox {
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  margin: 0px auto;
  border: 1px solid #DDDDDD;
  background-color: white;
}
</style>
export default {
    en: {
        inBasketError: 'This package is already in your basket',
        termsTitle: "Terms and Conditions of Simiaroom",
        offlinePayment: {
            title: "Choose your payment method",
            close: "Close",
            action: "Record payment",
            desc: "Deposit the cost of your consulting package to the above account and after payment, enter the payment information in the following fields, and wait for the confirmation of the financial department and the call from Simiaroom.",
            paymentDate: "Date of payment",
            currency: "Currency",
            PaymentAmount: "Amount",
            return: "Return to payment methods"
        },
        next: 'Next',
        yes: 'Yes',
        no: 'No',
        today: "Today",
        emptyStateCart: "Your shopping cart is empty!",
        session_card: {
            session: "",
            therapist_name: "Therapist :",
            used: "Held",
            can_enter: "Entering the meeting",
            date_fixed: "Waiting to be held"
        },
        menu: {
            factor: "Factor",
            dashboard: 'Dashboard',
            messenger: 'Messages',
            sessions: 'Sessions',
            tasks: 'Tasks',
            packages: 'Packages',
            profile: 'Profile',
            file: 'File',
            setting: "Profile",
            wallet: "Wallet",
            close: "Close",
            finance: "Finance"
        },
        cycle: "Cycle",
        section_title: {
            gift_code: "Enter the gift code",
            wallet: "Wallet transactions",
            personalInfo: "Personal information",
            moreInfo: "Rest of Information",
            cycles: "Cycles"
        },
        cycles: {
            'Individual': 'Individual',
            'Couple': 'Couple',
            'Family': ' Family',
            'Child and Adolescent': 'Child and Adolescent',
            'Sex Therapy': 'Sex Therapy',
            'Psychiatry': 'Psychiatry',
            'Children and adolescents, growth, parenting': 'Children and adolescents, growth, parenting',
            'Couple therapy': 'Couple therapy',
            'Sex therapy': 'Sex therapy'
        },
        clientStepper: {
            dataTime: {
                hint: "Please enter your Preferred times  slot (on time zone) for the coming session. Adding More time slots  helps us to schedule your session faster.",
                typeCalender: [
                    'Gregorian',
                    'Solar'
                ],
                periods: [
                    'Morning',
                    'Noon',
                    'Afternoon',
                    'Night'
                ],
                changeTimeZone: "Change Time Zone",
                changeTimeZoneHint: "Enter preferred times for session after changing the time zone",
                tables: {
                    header: [
                        'Date',
                        'Times',
                    ],
                }
            }
        },
        knway: {
            youtube: "Youtube",
            google: "Google search",
            twitter: "twitter",
            instagram: "instagram",
            podcast: "podcast",
            bannerads: "Banner ads",
            friends: "Introduce friends and acquaintances",
            site: "site",
            other: "other",
        },
        giftCode: {
            title: "Add gift code",
            placeholder: "code"
        },
        errors: {
            fillIsRequired: 'This field is required.',
            passwordLengthError: "Password must be more than 8 characters",
            emailErrValid: "Enter valid Email address",
            phoneNumberError: "Invalid Phone Number."
        },
        gender: {
            female: 'Female',
            FEMALE: 'Female',
            male: 'Male',
            MALE: 'Male',
            OTHER: 'Others',
            other: 'Others',
        },

        relationships: {
            single: 'Single',
            married: 'Married',
            inrel: 'Partner ',
            other: 'Other ',
            divorced: "Divorce",
            partner: ' Others'
        },
        loginComponent: {
            testLogin: "Login/ Sign-up with your account to receive your test commentary",
            login: "Login",
            title: "Welcome to Simiaroom",
            subTitle: "In order to Purchase, Please Login to your Account",
            form: [
                'Email Address',
                'Password '
            ],
            registerLabel: "Don't you have an account?",
            noAccount: "I don't have an Account!",
            haveAccount: "I have an Account!",
            registerAction: "Sign-Up/ Registration",
            resetPassword: "Forgot Password?",
            forgetPassword: {
                title: "Forgot Password",
                subTitle: "Password re",
                action: "Get Link",
                loginLabel: "Already have an account?",
                loginAction: "Login",
            },
            checkEmail: "Check your Email Inbox",
        },
        medicalView: {
            history: "Have you ever suffer from Medical Condition/Illness",
            servicesHistory: "",
            suicide: {
                yes: "Yes",
                no: "No",
                yesDesc: "I Have!",
                noDesc: "I Have not! "
            },
            yes: "Yes",
            no: "No",
            psychology: "Psychology",
            psychiatry: "Psychiatry",
            mentalHospital: "Mental Institution/Hospital",
            HistorySuicide: "Have you ever Thought about Suicide?",

        },
        dashboard: {
            modal: {
                title: "Your information has been successfully registered.",
                desc: "You enter your user panel and through the user panel you can find out about the status of your sessions and communicate with your dedicated support.",
                action: "Confirm"
            },
            chat: {
                action: "Chat",
                title: "Chat with support",
                placeholder: "Message"
            },
            wallet_status: {
                title: "Credit balance of your sessions:",
                subtitle: " Purchase the package here ",
                action: " Buy"
            },
            session_status: {
                title: "The status of your next session",
                subtitle: " Your session wallet is empty. Please purchase the package ",
                action: "Buy"
            },
            sessions: {
                title: ' Upcoming Sessions ',
                subtitle: ' Reserved Sessions ',
                action: " Details "
            },
            walletSession: {
                title: 'Package Remaining',
                subtitle: 'Sessions Remaining',
                action: 'Purchase '
            },
            wallet: {
                title: 'Transactions',
                subTitle: 'Add Gift Code'
            },
            inviteFriends: {
                title: ' Share the Happiness !',
                subtitle: "10 Dollar Gift ",
                action: 'Invite a friend'
            },
            tests: {
                title: 'Free Psychological Tests'
            }
        },
        basket: {
            title: "Selected Packages",
            addGiftCode: "Gift Code ",
            factor: "Receipt",
            discountCode: "Coupon and Discount code",
            off: "Offers and Coupons",
            wallet: "wallet",
            finalPrice: "Final Price",
            onlinePay: "Online Payment",
            offlinePay: "Offline Payment ",
            datatable: {
                title: "Package type ",
                qty: "Numbers",
                price: "Unit Price",
                totalAmount: "Total Price"
            }
        },
        wallet: {
            charge: 'Gift Card',
            use: 'Payment',
            balance: 'account Balance',
            wallet: 'wallet',
            transaction: 'Transaction',
            title: "Cart ",
        },
        messenger: {
            fileToolTip: "File Attachment",
            imgToolTip: "Send Video",
            messagePlaceholder: "Text in here...",
            emptyState: "Empty"
        },
        jobs: {
            employed: 'Employed',
            unemployed: 'Unemployed',
            housewife: 'Housewife',
            student: 'Student',
            other: 'Others'
        },
        country: "Country",
        edit: "Edit",
        city: "City",
        tz: "Local Time Zone ",
        noTz: "No Time Zone",
        child: "Offspring",
        marital: "Marital status",
        reserve_session: {
            segment_control: {
                title: "Subject",
                time: "Time",
                reserve: "Reserve",
            }
        },
        segments: {
            reserve: 'Reserve ',
            packages: "Packages",
            profile: "General Information ",
            medicalInfo: "Medical Information ",
            factors: "Receipt",
            wallet: "Wallet"
        },
        session_wallet: {
            empty: {
                title: "There are no credits or packages for booking sessions !",
                action: "charge"
            },
            general_session: "Sessions",
            therapy_session: "Therapy Session",
            action: "Reserve"
        },
        select_cycle: {
            titleSection: "Therapy Subject",
            title: "Subject",
            package: "Package",
            duration: "45 Minutes",
            description: "ِDescription",
            selectCycle: "other packages",
            action: "Confirm and Continue",
            notify: {
                selectCycle: "Select your package please",
                addit: "package added"
            },
            modal: {
                modalTitle: "Therapy Topic/Subject",
                description: "Select your therapy Topic/Subject",
                select_title: "Select your therapy Topic/Subject",
                add_cycle: "Add Topic/Subject",
                add_description: "Add Description",
            },
            cycle_heading: {
                Individual: {
                    title: "Individual",
                    description: "For Myself "
                },
                Couple: {
                    title: "Couple and Family",
                    description: "for my spouse and I"
                },
                family: {
                    title: "Family",
                    description: "For My family"
                },
                kid: {
                    title: "Child and Adolescent",
                    description: "For My child"
                },
                sex: {
                    title: "Sex Therapy",
                    description: "For my partner and I"
                },
                Psychiatry: {
                    title: "Psychiatry",
                    description: "For medical diagnosis and prescription"
                }
            }
        },
        download: "Download",
        description: "Description",
        select_time: {
            title: "time preferences",
            description: "Select your time preferences",
            continue: "Confirm and Continue",
        },
        accept_request: {
            title: "Request Submitted",
            newUser: "your appointment will be fixed after therapist approval" +
                "out support team will be in touch for fixing an assessment and pre therapy session. This 15-minute session will help you to choose the best match therapist for your therapeutic roadmap.",
            oldUser: " your appointment will be fixed after therapist approval "
        },
        factors: {
            datatable: {
                date: "Date",
                type: "type",
                state: "Status",
                amount: "Price"
            },
            factorState: {
                paid: "Successful Payment",
                submit: "Pre-Purchase/Payment",
                canPay: "Payment due ",
                partially_paid: "Advance Payment"
            },
            paymentState: {
                awaiting: "awaiting",
                paid: 'Paid/ Successful Payment ',
                wallet_pending: "",
                failed: "failed",
                debit_checkout: "Failure to pay",
                rejected: "rejected",
                pending: "pending"
            },
            paymentType: {
                wallet_debt_settlement: " Debt ",
                online: "online",
                wallet_payment: "wallet",
                offline: "offline",
            }
        },
        product: "Product",
        details: "Detail",
        factor: "Receipts",
        close: "Close",
        packageFactor: "Package Receipts:",
        paymentDetail: "Payment Details:",
        //ssssss
        account: 'Dashboard',
        nextLabel: 'Next',
        Paid: 'Successful Payment',
        return: "Return",
        confirm: 'Confirm',
        confirmAndContinue: "Confirm and Continue",
        assignconsult: {
            successMessage: "Payment Successful!",
            failedMessage: "Oops! Something went Wrong. We couldn't Process your payment.",
            code: "Transaction Number",
            sorry: 'Sorry',
            oldUserTexts: [
                "Oops! Something went Wrong. We couldn't Process your payment.",
                "Don't worry, You can text the support team via WhatsApp or Telegram to complete the process.",
            ],
            newUserTexts: [
                "Oops! Something went Wrong. We couldn't Process your payment.",
            ]
        },
        sentEmailLink: 'Link has been sent/mailed to your Email Address',
        dontHaveAccount: "Don't have an account?",
        phoneLabel: 'Phone Number',
        matchMaking: {
            pleaseAnswer: 'Answer Few Questions, please.',
            lastMessage: [
                'Approximately',
                'of the people who gave answers similar to yours mentioned the following things in the pre-counseling session and initial therapy sessions (psychological consultation):',
                'Next, after completing the registration process, you can discuss all your conditions and needs with Simiarum\'s psychology specialist in the pre-consultation session.\n',
            ],
            chooseFieldQuestion: 'Select your Therapy Topic/Subject',
            buttons: {
                submit: 'Submit',
                firstStep: 'First Step'
            },
            registerForm: {
                registerByGoogle: ' Register by Google Account',
                registerByEmail: 'Sign-Up/ Register by Email'
            },
            primaryResult: {
                1: [
                    '1. Disturbance in sleep and appetite ',
                    '2. Feeling tired due to continuous stress',
                    '3. Difficulty in managing emotions, especially anger',
                    '4.  suffering from cardiovascular Conditions',
                ],
                2: [
                    '1. Difficulty in managing emotions, especially anger',
                    '2. Increase in anxiety symptoms',
                    '3. Challenges in interpersonal communication and partnership',
                ],
                3: [
                    '1. Concentration and Attention Disturbance ',
                    '2. procrastination',
                    '3. Learning and memory problems',
                    '4. Challenges in interpersonal communication'
                ],
                4: [
                    '1. Anxiety Disorders',
                    '2. Procrastination,',
                    '3. Concentration and Attention Disturbance ',
                ],
                5: [
                    '1. Anxiety Disorder',
                    '2.procrastination',
                    '3. Perfectionism',
                    '4.  Concentration and Attention Disturbance',
                ],
                6: [
                    '1.low mood and depression (Cold and Blue)',
                    '2. Difficulty in managing stressful situations',
                    '3. identity and attachment issues',
                ],
                7: [
                    '1. Challenges in Relationship and Partnership',
                    '2. increase in anxiety symptoms',
                ],
                8: [
                    '1. increase in anxiety symptoms',
                    '2. rumination and obsessions',
                    '3. challenges with family and friends',
                ],
                9: [
                    '1. low mood and depression (Sad and Blue)',
                    '2. challenges of intercultural and intergenerational differences',
                    '3. sexual problems',
                ],
                10: [
                    '1. Anxiety Disorders',
                    '2. rumination and obsessions',
                    '3. Challenges in Relationship and Partnership',
                ],
                11: [
                    '1.Difficulties in Emotion Management',
                    '2.Substance Abuse',
                    '3. challenges in interpersonal relationships',
                ],
                12: [
                    '1. challenges in interpersonal relationships',
                    '2. Challenges in the occupation/education',
                    '3.  procrastination',
                    '4. suicidal Ideation',
                ],
                13: [
                    '1. Depressive Disorder',
                    '2. Rumination and worries about body and Self-Image',
                    '3. Anxiety Disorders',
                ],
                14: [
                    '1. Anxiety disorders, including panic attacks',
                    '2. low mood and depression (Sad and Blue)',
                    '3. challenges in interpersonal relationships',
                    '4. Challenges in the occupation/education',
                ]
            },
            profile: 'Profile/Record',
            selectTherapist: 'Therapist Selection',
            register: 'Registration',
            description: 'Description',
            hintTitle: 'Thanks for your answers!',
            hintDescription: [
                'Additional information will be received and reviewed during the Pre-Session. There are also some general questions due to understanding your current condition.'
            ],
        },
        signUpLabel: "Sign-Up/ Registration",
        accountRecoveryCaption: 'Enter your email to recover your account',
        accountRecovery: 'account recovery',
        emailErrRequired: 'Email Required',
        passwordLabel: 'Password',
        passwordConfirmationLabel: 'Re-type password',
        emailLabel: 'Email',
        onboarding: {
            country: {
                title: "Please select your country of residence",
                subTitle: "We need this information to schedule sessions based on your timezone."
            },
            startStep: {
                title: "مشاوره روانشناسی سیمیاروم چگونه کار می کند؟",
                reasons: [
                    '1 - یک نام کاربری و رمز عبور خصوصی برای دسترسی به سیستم ایجاد کنید.',
                    '2 - اطلاعات پرداخت را وارد و مبلغ پکیج مورد نظر را پرداخت نمایید.',
                    '3 - یک جلسه پیش مشاوره برای شناخت بیشتر و تعیین درمانگر شما برگزار می‌شود.',
                    '4 - بر اساس نتیجه جلسه پیش مشاوره، ما شما را با یک درمانگر متخصص هماهنگ می‌کنیم.'
                ]
            },
            stepTwo: {
                title: "What is Chief Complaint? (Choose the main Reason)",
                why: {
                    1: "احساس افسردگی کرده ام",
                    2: "احساس اضطراب یا مستاصل شدن (پریشان حالی و کلافه بودن) می کنم",
                    3: "خلق و خوی من با عملکرد شغلی / درسی ام تداخل دارد",
                    4: "با ایجاد یا حفظ روابط چالش دارم",
                    5: "نمی توانم هدف و معنایی در زندگی ام پیدا کنم",
                    6: "غصه میخورم",
                    7: "ضربه (چالش) روحی را تجربه کرده ام",
                    8: "باید درباره یک چالش یا موقعیت خاص صحبت کنم",
                    9: "می خواهم اعتماد به نفسم را تقویت کرده یا بدست بیاورم",
                    10: "می خواهم خودم را بهبود بخشم اما نمی دانم از کجا شروع کنم",
                    11: "استفاده از خدمات مشاوره به من توصیه شده است (دوست، خانواده، دکتر)",
                    12: "سایر",
                }
            },
            stepThree: {
                title: "انتظارات شما از درمانگرتان چیست؟ درمانگری که…",
                exception: {
                    1: ' شنونده خوبی باشد',
                    2: ' تجربه سکونت در خارج از کشور را داشته باشد',
                    3: ' گذشته من را کاوش می کند',
                    4: ' به من مهارت های جدیدی می آموزد',
                    5: ' باورهای من را به چالش می کشد',
                    6: ' به من تکلیف می دهد',
                    7: ' مرا برای تعیین اهداف راهنمایی می کند',
                    8: ' فعالانه با من در ارتباط بوده و مسائل من را چک می کند',
                    9: ' سایر',
                    10: ' من نمی دانم',
                }
            },
            hint: {
                title: "مشاوره روانشناسی و طول دروه درمان من چند جلسه خواهد بود؟",
                hints: [
                    'حداقل زمانی که شما نیاز دارید تا از اثر مشاوره در زندگی خود آگاه شوید، 5 جلسه است.',
                    'طبق آمار جهانی،',
                    'حداقل میانگین دوره درمان',
                    'روانشناسی 13 تا 16 جلسه بوده،',
                    'اما تعداد این جلسات براساس نوع روش درمان و میزان شدت مسائل ',
                    'شما می‌تواند بیشتر یا کمتر باشد.',
                    '                      بخاطر داشته باشید مسائلی که مدت زیادی شما را دچار چالش کرده است نیازمند زمان جهت بهبود می‌باشد.'
                ]
            }
        },
        register: "Register",
        addCycleModal: {
            add: "Add",
            title: "please write down your chief complaint and describe your problem",
            countChar: "Minimum of 20 Characters",
            categories: [
                'Individual',
                'Couple',
                'Family',
                'Child and Adolescent',
                'Sex Therapy ',
                'Psychiatry',
            ],
            categorySession: "Therapy Subject/Topic ",
            problemDescription: "chief Complaint (Issue Description)",
            problemDescriptionPlaceHolder: "Please explain your main Reason and Chief Complaint:",
            selectCycle: {
                title: "ُpackage selection",
                selecting: "Please select your preferred package",
                confirm: "Confirmed",
                action: "add new package",
                issueSession: "therapy subject:",
                totalSession: "Total session",
                request: "session request",
            },
        },
        loginLabel: 'Login',
        emailErrValid: 'Please enter a valid Email address',
        passwordErrRequired: 'Password required',
        Notify: {
            without_cycle: "To register a meeting request, first complete your information",
            session_request: "The session request was successfully registered",
            medicalEdit: "Added successfully",
            requiredChat: "Please enter the text of your message",
            loginSuccess: "Successful Login",
            loginFailed: "Wrong Username/Password",
            doctorTime: [
                'Just Select one Time/Date',
            ],
            successfully: "Successfully Registered",
            successfullyV2: "Registration Completed",
            successfullyWithRedirect: "Successfully registered, you will be redirected to your dashboard and Profile",
            successfullyRequest: 'Your request submitted',
            successDateTime: "Your information has been successfully registered, we will contact you within the next 24 hours",
            changePassword: "Password Successfully Changed",
            packageComponent: "Please select at least one currency for each package",
            changeStatus: 'Please select a Therapy Time and Date',
            DateTime: {
                selectDateFirst: "Please Select the Date",
                selectDay: "Please select at least one Time and Date",
                success: "Your selected times have been registered successfully",
            },
            clientSessionRequest: 'Please select your therapy time and date',
            signupComponent: {
                phoneNumberError: "Phone Number Invalid.",
                success: "Regestration Compeleted Successfully",
                emailError: "Email already used/Existed",
                passwordError: "Password Incorrect",
                passwordLengthError: "The minimum password is 8 characters",
                rePasswordRequired: "This field is required. ",
                rePasswordCheck: 'password and Re-enterd password are not the same',
                phoneRequired: "Phone Number Required",
                countrySelected: "Select Regional Code",
                passwordRequired: "Password reenter required",
                notSamePassword: 'password and Re-enterd password are not the same',
            },
            login: {
                password: {
                    passwordRequired: "Password is required",
                    minLength: "The minimum password is 8 characters"
                }
            },
            acceptTerms: "Agreement to the terms and conditions is required.",
            calender: {
                successDelete: "Successfully deleted"
            },
            error: 'An error has occurred',
            connectionError: "Please check your internet connection",
            addNewCycle: {
                oneSession: "the minimum is one session.",
                notEnough: "Not Enough Sessions.",
            },
            clientDashboard: {
                selectTime: "Please select a Therapy Time and Date",
                selectOne: "Please select one of the options",
                success: "Your time have been successfully recorded",
                selectTimespan: "Please select at least one time duration"
            },
            ExperssComponent: {
                selectOne: "Please Select a therapy time and date",
                successSelect: "Your suggested times have been successfully registered"
            },
            CartComponent: {
                enterCode: "Enter Coupon/discount Code",
                needLogin: "Please sign-up/Login to your account"
            },
            cycle: {
                needPackage: "No Package existed, please purchase before Reservation"
            },
            sessionCountRules: {
                countRequired: "Session Numbers",
                minCount: "The number of sessions must be at least 1",
                buyError: "The number of requested sessions must be less than your purchased sessions"
            },
            successCopy: ' Copied'
        },
        signupComponent: {
            title: "Create account",
            subTitle: "Please fill out boxes below",
            form: [
                "Phone Number (with Regional Code)"
            ],
            terms: "accept the Terms and Conditions",
            accept: '.',
            inviter: "Invitation Code",
            action: "Sign Up/ Register",
            loginLabel: "Already have an account?",
            loginAction: "Login",
            phone: "Phone Number",
            registerAction: "Register",
            acceptTerms: 'I accept the Terms and Condition',
            loginActionOnBoarding: "Login",
        },
        onBoarding: {
            titleLogin: "Sign-Up",
            continue: 'Continue',
            next: "Next",
            prev: "Previous",
            selectedPackage: 'Package Selection ',
            stepOne: {
                title: 'Toward a better Me',
                subTitle: "For a better matching, please answer some quick questions.",
                start: "Start",
                skip: "Not now!"
            },
            stepTwo: {
                title: "Select your therapy topic/Subject",
                subtitle: "you can change all the profile information whenever you want!.",
                cycles: [
                    'Individual Therapy',
                    'Relationship and Couple',
                    'Child and Adolescent',
                    'Family Therapy',
                    'Psychiatry',
                    'Sex Therapy'
                ],
            },
            stepThree: {
                title: "Choose your gender preference",
                gender: [
                    'Male (He/His)',
                    'Female (She/Her)',
                    'Other'
                ]
            },
            stepFour: {
                title: 'Your Age:',
                agePeriods: [
                    '18 or Less',
                    '46 or more'
                ]
            },
            stepFive: {
                title: "Choose your Relation/Marital status:",
                relationships: [
                    'Single',
                    'Married',
                    'In Relationship ',
                    'Others'
                ]
            },
            history: "Have you ever tried therapy before?",
            loginWithDifferentWay: "Login with:",
            loginItems: [
                'Googe Account',
                'Facebook Account'
            ],
            terms: "Terms and Conditions ",
            wizardLogin: [
                'Sign-Up/ Registration',
                'Login'
            ],
            wizardStepIndicator: {
                info: "Information",
                register: "Registration ",
                pay: "Payment",
                selectConsulter: "Therapist and Date Selection"
            },
            whoSelectDoctor: {
                title: "How do you prefer select your therapist?",
                items: [
                    'I will choose all by myself',
                    "I want to have SimiaRoom Specialists' Suggestions!",
                ]
            },
            selectDoctor: {
                searchPlaceHolder: 'Search: Name',
                labelCategory: 'Expertise',
                all: 'All'
            },
            selectTime: {
                title: "preferred time and Date",
                subTitle: "Please select your preferred Time and Date for your therapy session"
            },
            testimonial: {
                sweden: {
                    title: 'Sima from Sweden',
                    desc: "I am very happy that I found you and I was able to get help from you and my therapist in a situation where I could not trust anyone. I am much better now and I really thank you."
                },
                usa: {
                    title: 'Ebrahim From US',
                    desc: "Therapy changed my Life Attitude! Being Relieved from suffering was the most enjoyable gift from Therapy insight. Thank you and your Therapists."
                },
                germany: {
                    title: 'Delnaz from Germany ',
                    desc: "I had a bad-therapy experience and I know how much it can hurt you so I was afraid to start trusting again. Until my friend introduced you. I am so grateful for my current Happiness."
                },
                usaTwo: {
                    title: 'Shadi from US',
                    desc: "Thank you Simiarom and the counselors. I really haven't seen this system anywhere that even change their own therapists in order to give you the best quality!"
                },

                priceBox: {
                    select: 'package selection',
                    title: 'Select your Package ',
                    subtitle: 'choose your preferred package',
                    labelPopular: 'Most Popular',
                    labelBestPrice: 'Best Price',
                    hint: 'Unconditional refund in case of dissatisfaction',
                    emptyBasket: 'Empty Cart'
                }
            },
            signupComponent: {
                title: "Create account",
                subTitle: "Please fill out boxes below",
                form: [
                    "Phone Number (with Regional Code)"
                ],
                terms: "accept the Terms and Conditions",
                accept: '.',
                inviter: "Invitation Code",
                action: "Sign Up/ Register",
                loginLabel: "Already have an account?",
                loginAction: "Login",
                phone: "Phone Number",
                registerAction: "Sign Up/ Register",
                acceptTerms: 'I accept the Terms and Condition',
                loginActionOnBoarding: "Login",
            },
            logout: 'Log out',
            showAccount: "Account",
            $vuetify: {
                noDataText: 'No entry'
            },


            welcome: 'Welcome to your Personalized Dashboard',
            singleFactor: {
                title: "Selected Packages",
                addGiftCode: "Add Gift Code",
                factor: "Recipt",
                discountCode: "Coupon and Discount Code",
                off: "Discount/ Gift Code",
                wallet: "wallet",
                remaining: "Remaining",
                finalPrice: "Final Price",
                onlinePay: "Online Price",
                offlinePay: "Offline Price ",
                payments: "Payments",
                datatable: {
                    title: "Package Type",
                    qty: "Number",
                    price: "Unit Price",
                    totalAmount: "Total Price",
                    off: "Discount"
                }
            },
            socialLogin: {
                enterNumber: "Enter your phone number, please"
            },
            CartComponent: {
                PreSessionModal: {
                    title: 'Pre-Counseling Session',
                    continue: "Confim and Continue",
                    text: {
                        leadUser: [
                            "out support team will be in touch for fixing an assessment and pre therapy session. This 15-minute session will help you to choose the best match therapist for your therapeutic roadmap.",
                        ],
                        oldUser: [
                            " your appointment will be fixed after therapist approval ",
                            'our support team will send your payment link',
                            'Contact us',
                        ]
                    },
                    action: 'Okay! '
                },
                payByWallet: "Use my wallet",
                clearingWallet: 'clear wallet',
                invoiceItems: {
                    total: " Total Price",
                    offer: "Discount",
                    finalPrice: "Final Price"
                },
                title: "selected Packages",
                price: "Price",
                headersTable: [
                    'Item',
                    'Unit Price',
                    'Numbers',
                    'Total Price ',
                    'Delete'
                ],
            }
        },
        notifications: {
            needBuy: {
                title: "No Package available",
                description: "Purchase a Package before Session Reservation",
                action: "Package Purchase"
            },
            reserveSession: {
                title: "Reserve Session",
                description: "Reserve a new Session",
                action: "Reserve Session"
            }
        },
        payableFactor: {
            title: "You have a Payment Receipt for",
            mobTitle: "You have a pending payment!",
            have: "",
            details: 'Details'
        },
        notify: {
            completeProfile: "Complete your profile, please",
            giftCodeSubmit: "Gift Code Submitted",
            couponCodeSubmit: "Coupon/Discount Code Submitted",
            deleteCouponCode: "Coupon/Discount Code deleted",
            notFoundCoupon: "Coupon/Discount Code not found",
            enterCouponCode: "Enter Coupon",
            failed: "Action Failed. ",
            passwordChange: "Password Changed",
        },
        sidebar: {
            calender: {
                title: "Session Booked/Reserved",
            },
            chat: "Chat with Support/Specialist",
            localClock: "Local Time Zone",
        },
        layout: {
            footer: {
                charge: " charge",
                reserve: "Reserve",
                wallet: "Wallet"
            }
        },
        currency: {
            longRial: 'Toman',
            rial: 'T',
            dollar: '$',
            dollarCanada: 'Dollar (Canada) ',
            dollarAustralia: 'Dollar (Australia)',
            Euro: '€',
            try: 'try',
            pound: 'pound',
        },
        routes: {
            dashboard: 'Overview',
            sessions: 'Sessions',
            wallet: 'Transactions',
            index: 'Home Page',
            packages: 'Packages/ Reserve',
            messenger: "Messenger",
            profile: 'My Record/Profile',
            tasks: 'Test & Task',
            userInfo: 'Setting',
            basket: 'Cart',
            factor: 'Receipt',
            personal_profile: "Personal info"
        },
        months: {
            farvardin: 'Farvardin',
            ordibehesht: 'Ordibehesht',
            khordad: "Khordad",
            tir: 'Tir',
            mordard: "Mordad",
            sharivar: "Shahrivar",
            mehr: "Mehr",
            aban: "Aban",
            azar: "Azar",
            day: "Dey",
            bahman: "Bahman",
            esfand: "Esfand",
        },
        days: {
            Saturday: "Saturday",
            Sunday: "Sunday",
            Monday: "Monday",
            Tuesday: "Tuesday",
            Wednesday: "Wednesday",
            Thursday: "Thursday",
            Friday: "Friday",
        },
        mobileMenu: {
            dashboard: "Dashboard",
            tasks: "Tasks",
            sessions: "Sessions",
            packages: "Packages",
            profile: "Records/ Profiles",
            setting: "Setting",
            wallet: "Ttansactions",
            account_manager: "Support",
            chat: "Messenger",
            terms: "Terms and Conditions/confidentiality",
            about: "About Us ",
            logout: " Sign Out "
        },
        sessions: {
            empty: "No Session Reserved",
            action: "Reserve",
            loading: "Loading... Thanks for your Patience",
            title: "Your sessions",
            session_request: "Request a new session",
            modal: {
                title: " Your counseling session has been successfully registered",
                subtitle: " Our experts will contact you within 48 hours ",
                action: "Confirm"
            }
        },
        packages: {
            perSession: "Per Session",
            buy: "Purchase ",
        },
        tasks: {
            empty: {
                title: "No Task/Test assigned!",
                text: "Tasks and Tests will be assigned..."
            }
        },
        resetPassword: {
            title: "Reset/Forget Password",
        },
        profileView: {
            name_hint: 'You can use a pseudonym.',
            email: "email",
            degree: "Degree",
            contact: "Contact Info ",
            username: "User",
            job: "Job",
            sex: "Gender",
            moreInfo: "More Information",
            non: "گفته نشده",
            child: "Child",
            noKid: "No child",
            age: "Age",
            localTime: "Timezone",
            moreInfoModal: {
                countryLabel: "Select your Location",
                localTime: "Select your Time Zone",
                childCount: "How Many kids do you have?",
                noChild: "No child"
            },
            jobModal: {
                title: " ٌWhat is your career status?",
            },
            birthDate: "Birth Date",
            name: "Name",
            family: "Last Name ",
            jobStatus: "Job/Career Status ",
            tell: "Phone Number",
            childOrder: "What is your birth order?",
            maritalStatus: "Marital Status ",
            childCount: " How Many kids do you have?",
        },
        userOnboarding: {
            specifications: "Details",
            childOrder: " What is your birth order?",
            maritalStatus: "What is your Marital Status",
            childCount: " How Many kids do you have?",
            jobStatus: " ٌWhat is your career status?",
            country: "ٌWhere do you live?",
            tz: "Time Zone",
            city: "City",
            notify: {
                done: "Done"
            }
        },
        new_onboarding: {
            next: "Next page ",
            prev: "Previous page",
            new_indicator: {
                info: "Demography",
                title_session: "Therapy field",
                register: "Registration"
            },
            stepOne: {
                text: [
                    "We are very happy to be with you. Whenever you enter your information ",
                    'Your meetings will be arranged. Meetings will be held online with ',
                    'Google meet',
                    '',
                    'Before the main sessions, you have a pre-consultation.'

                ],
                action: "Complete information"
            },
            stepTwo: {
                title: "Please enter your name and details here.",
                name: "Name",
                family: "Last Name ",
                tell: "Phone Number",
                birthDay: "Birthdate*"
            },
            stepThree: {
                title: "Your location information is received to better match your local time with the therapist.",
                country: "Country",
                city: 'City',
                tz: 'Timezone',
                kenway: 'how know us'
            },
            stepFour: {
                title: " Please write a short description of your problem in this section. ",
                category: "Subject",
                desc: "Descrition"
            },
            idicatior: {
                info: " Information ",
                location: "Location",
                cycle: "Reason"
            },
        },
        education: {
            NoDegree: 'No degree',
            Diploma: 'Diploma',
            AssociateDegree: 'Associate Degree',
            Bachelor: 'Bachelor',
            MA: 'MA',
            PHD: 'P.H.D',
        },
    },
    fa: {
        inBasketError: 'این بسته در حال حاضر در سبد خرید شما وجود دارد.',
        termsTitle: "شرایط و قوانین سیمیاروم",
        offlinePayment: {
            title: "شیوه پرداخت خود را انتخاب کنید",
            close: "بستن",
            action: "ثبت پرداخت",
            desc: "هزینه بسته مشاوره خود را به حساب فوق واریز نمایید و پس از پرداخت، اطلاعات پرداخت را در فیلد های زیر وارد کنید، و منتظر تایید بخش مالی و تماس از طرف سیمیاروم باشید.",
            paymentDate: "تاریخ پرداخت",
            currency: "ارز پرداختی",
            PaymentAmount: "مبلغ پرداختی",
            return: "بازگشت به شیوه های پرداخت",
        },
        next: "بعدی",
        yes: 'بله',
        no: 'خیر',
        today: "امروز",
        emptyStateCart: "سبد خرید شما خالی است!",
        session_card: {
            session: "جلسه",
            therapist_name: "نام تراپیست :",
            used: "برگزارشده",
            can_enter: "درحال برگزاری",
            date_fixed: "در انتظار برگزاری"
        },
        menu: {
            factor: "تاریخچه خرید",
            dashboard: 'داشبورد',
            messenger: 'پیام‌ها',
            sessions: 'تاریخچه جلسات',
            tasks: 'تسک‌ها',
            packages: 'خرید جلسه',
            profile: 'پروفایل',
            file: 'پرونده',
            setting: " اطلاعات کاربری",
            wallet: "کیف‌پول",
            close: "بستن",
            finance: "امور‌مالی",
            session_request: "درخواست جلسه",
            tests: 'تست‌ها',
            invitees: 'دعوت‌ها'
        },
        cycle: "دوره",
        section_title: {
            gift_code: "ورود کد هدیه",
            wallet: "تراکنش های کیف پول",
            personalInfo: "اطلاعات شخصی شما",
            moreInfo: "سایر اطلاعات",
            cycles: "دوره های شما",
            location: 'اطلاعات مکانی',
            password: 'رمز عبور'
        },
        cycles: {
            'Individual': 'درمان فردی',
            'Couple': 'زوج درمانی',
            'Family': ' مشاوره خانواده',
            'Child and Adolescent': 'کودک و نوجوان',
            'Sex Therapy': 'مشاوره جنسی',
            'Psychiatry': 'روان پزشکی',
            'Children and adolescents, growth, parenting': 'کودک و نوجوان',
            'Couple therapy': 'زوج درمانی',
            'Sex therapy': 'زوج درمانی'
        },
        clientStepper: {
            dataTime: {
                hint: "لطفا همه زمانهای مناسب برای برگزاری جلسه آینده (به زمان محلی ) را وارد نمایید، هرچه تعداد بیشتری زمان وارد کنید، امکان برگزاری سریع تر جلسه را به تیم پشتیبانی میدهید.",
                typeCalender: [
                    'تقویم میلادی',
                    '              تقویم شمسی'
                ],
                periods: [
                    'صبح',
                    'ظهر',
                    'عصر',
                    'شب'
                ],
                changeTimeZone: "تغییر زمان محلی",
                changeTimeZoneHint: "با تغییر زمان محلی، باید زمانهای مناسب برگزاری جلسه را مجددا وارد کنید",
                tables: {
                    header: [
                        'تاریخ',
                        'ساعت ها',
                    ],
                }
            }
        },
        education: {
            NoDegree: 'فاقد مدرک',
            Diploma: 'دیپلم',
            AssociateDegree: 'فوق دیپلم',
            Bachelor: 'لیسانس',
            MA: 'فوق لیسانس',
            PHD: 'دکتری',
        },
        new_onboarding: {
            idicatior: {
                info: " تکمیل اطلاعات ",
                location: " پیش مشاوره",
                cycle: "علت مراجعه",
                final: 'ثبت'
            },
            new_indicator: {
                info: "مشخصات",
                title_session: "موضوع مشاوره",
                register: "ثبت نام"
            },
            next: "مرحله بعد",
            prev: "مرحله قبل",
            stepOne: {
                text: [
                    "خیلی خوشحالیم در کنارتیم.بعد از تکمیل اطلاعات جلسه‌ شما تنظیم می‌شه.",
                    ' جلسات به صورت آنلاین و با',
                    'Skype',
                    ' برگزار می‌شه.',
                    'راستی قبل از شروع جلسات اصلی، یک پیش مشاوره هم داری.'

                ],
                action: "تکمیل اطلاعات"
            },
            stepTwo: {
                title: "در صورت تمایل می‌توانید از نام و نام‌خانوادگی مستعار خود استفاده کنید.",
                name: "نام",
                family: "نام خانوادگی ",
                tell: "شماره واتساپ و تلگرام (به همراه کد کشور)",
                birthDay: "تاریخ تولد"
            },
            stepThree: {
                title: " اطلاعات مکانی تنها برای تطبیق بهتر زمان برگزاری جلسات شما دریافت می‌شود. ",
                country: "کشور",
                city: 'شهر',
                tz: 'محدوده زمانی',
                kenway: 'نحوه اشنایی با ما'
            },
            stepFour: {
                title: " لطفا موضوع مشاوره خود را انتخاب کنید.",
                category: "موضوع مراجعه",
                desc: "شرح مساله"
            },
        },
        knway: {
            youtube: "یوتیوب",
            google: "جست و جو در  گوگل",
            twitter: "توییتر",
            instagram: "اینستاگرام",
            podcast: "پادکست",
            bannerads: "تبلیغات بنری",
            friends: "معرفی دوستان و آشنایان",
            site: "سایت",
            other: "موارد دیگر",
        },
        giftCode: {
            title: "افزودن کد هدیه",
            placeholder: "کد هدیه"
        },
        notifications: {
            needBuy: {
                title: "بسته‌ای وجود ندارد",
                description: "برای رزرو جلسه جدید بسته تهیه کنید",
                action: "تهیه بسته"
            },
            reserveSession: {
                title: "رزرو وقت جلسه",
                description: "جلسه جدید برای خود رزرو کنید",
                action: "رزرو وقت جلسه"
            }
        },
        payableFactor: {
            title: "شما یک فاکتور قابل پرداخت به مبلغ",
            mobTitle: "شما یک فاکتور در انتظار پرداخت دارید!",
            have: "دارید",
            details: 'جزئیات'
        },
        notify: {
            completeProfile: "لطفا فرایند ثبت‌نام خود تکمیل کنید",
            giftCodeSubmit: "کد هدیه اعمال شد",
            couponCodeSubmit: "کد تخفیف اعمال شد",
            deleteCouponCode: "کد تخفیف با موفقیت از روی سبد خرید شما حذف شد",
            notFoundCoupon: "کد تخفیف پیدا نشد",
            enterCouponCode: "کد تخفیف را وارد کنید",
            failed: "فرایند با مشکل مواجه شد. دوباره تلاش کنید",
            passwordChange: "رمز عبور تغیر کرد",
        },
        sidebar: {
            calender: {
                title: "جلسات تنظیم شده",
            },
            chat: "چت با پشتیبان اختصاصی",
            localClock: "وقت محلی",
        },
        layout: {
            footer: {
                charge: "افزایش اعتبار",
                reserve: "رزرو وقت",
                wallet: "کیف‌پول"
            }
        },
        months: {
            Farvardin: 'فروردین',
            farvardin: 'فروردین',
            Ordibehesht: 'اردیبهشت',
            ordibehesht: 'اردیبهشت',
            Khordaad: "خرداد",
            khordad: "خرداد",
            Tir: 'تیر',
            tir: 'تیر',
            Amordaad: "مرداد",
            mordard: "مرداد",
            Shahrivar: "شهریور",
            sharivar: "شهریور",
            Mehr: "مهر",
            mehr: "مهر",
            Aabaan: "آبان",
            aban: "آبان",
            Aazar: "آذر",
            azar: "آذر",
            Dey: "دی",
            day: "دی",
            Bahman: "بهمن",
            bahman: "بهمن",
            Esfand: "اسفند",
            esfand: "اسفند",
        },
        days: {
            Saturday: "شنبه",
            Sunday: "یکشنبه",
            Monday: "دوشنبه",
            Tuesday: "سه شنبه",
            Wednesday: "چهار شنبه",
            Thursday: "پنج شنبه",
            Friday: "جمعه",
        },
        mobileMenu: {
            dashboard: "داشبورد",
            tasks: "تسک‌ها",
            sessions: "جلسات",
            packages: "بسته‌ها",
            profile: "پرونده‌",
            setting: "تنظیمات",
            wallet: "تراکنش‌ها/کیف پول",
            account_manager: "پشتیبان اختصاصی",
            chat: "پیام‌رسان",
            terms: "حریم خصوصی",
            about: "درباره سیمیاروم",
            logout: "خروج از حساب"
        },
        sessions: {
            empty: "وقت جلسه‌ تنظیم نشده",
            action: "رزرو‌وقت",
            loading: "درحال دریافت اطلاعات",
            title: "جلسات شما",
            session_request: "درخواست جلسه جدید",
            modal: {
                title: " جلسه مشاوره شما با موفقیت ثبت شد ",
                subtitle: " کارشناسان ما تا 48 ساعت اینده با شما تماس خواهند گرفت ",
                action: "متوجه شدم"
            }
        },
        packages: {
            perSession: "برای هر جلسه",
            buy: "خرید بسته",
        },
        tasks: {
            empty: {
                title: "تمرینی و آزمونی برای شما ارسال نشده است!",
                text: "بر حسب نیاز تمرین و آزمون برای شما ارسال می‌شود"
            }
        },

        resetPassword: {
            title: "تغییر رمز عبور",

        },
        profileView: {
            name_hint: 'می‌توانید از نام مستعار استفاده کنید',
            en_name: 'نام انگلیسی',
            en_family: 'نام خانوادگی انگلیسی',
            email: "ایمیل",
            contact: "اطلاعات تماس",
            username: "کاربر",
            job: "شغل",
            sex: "جنسیت",
            moreInfo: "سایر اطلاعات",
            non: "گفته نشده",
            child: "فرزند",
            noKid: "فرزند ندارم",
            age: "سن",
            localTime: "زمان‌محلی",
            moreInfoModal: {
                countryLabel: "کجا هستید؟",
                localTime: "زمان محلی خود را انتخاب کنید",
                childCount: "چند فرزند دارید؟",
                noChild: "فرزند ندارم"
            },
            jobModal: {
                title: "وضعیت شغلی شما چیست؟",
            },
            birthDate: "تاریخ‌تولد",
            name: "نام",
            family: "نام خانوادگی",
            jobStatus: "وضعیت شغلی",
            tell: "شماره واتساپ و تلگرام (به همراه کد کشور)",
            childOrder: "چندمین فرزند خانواده",
            maritalStatus: "وضعیت تاهل",
            childCount: "تعداد فرزند",
            degree: 'مدرک تحصیلی'
        },
        userOnboarding: {
            specifications: "مشخصات",
            childOrder: "فرزند چندم خانواده هستید؟",
            maritalStatus: "وضعیت تاهل شما چگونه است؟",
            childCount: "چند فرزند دارید؟",
            jobStatus: "وضعیت شغلی شما چگونه است؟",
            country: "کشور محل اقامت",
            tz: "منطقه‌زمانی",
            city: "شهر محل اقامت...",
            notify: {
                done: "ویرایش پروفایل انجام شد",
                tz: "منظقه زمانی ویرایش شد"
            }
        },
        medicalView: {
            history: "قبلا بیماری خاصی داشتید؟",
            servicesHistory: "تا کنون از این خدمات استفاده کرده‌اید؟",
            yes: "بلی",
            no: "خیر",
            psychology: "روانشناسی",
            psychiatry: "روانپزشکی",
            mentalHospital: "بیمارستان روانی",
            HistorySuicide: "تا کنون سابقه خودکشی داشته‌اید؟",
            suicide: {
                yes: 'بله',
                yesDesc: "سابقه‌دارم",
                no: "خیر",
                noDesc: "سابقه ندارم"
            }
        },
        messenger: {
            fileToolTip: "ارسال فایل",
            imgToolTip: "ارسال عکس",
            messagePlaceholder: "یک پیام بنویسید",
            emptyState: "هنوز چیزی نوشته نشده"
        },
        jobs: {
            employed: 'شاغل',
            unemployed: 'فاقد‌ شغل',
            housewife: 'خانه‌دار',
            student: 'دانشجو',
            other: 'دیگر'
        },
        country: "کشور",
        city: "شهر",
        edit: "ذخیره تغیرات",
        knwayLabel: 'نحوه اشنایی با ما',
        tz: "زمان محلی",
        noTz: "بدون‌زمان",
        child: "فرزند",
        marital: "تاهل",
        reserve_session: {
            segment_control: {
                title: "موضوع",
                time: "زمان",
                reserve: "رزرو",
            }
        },
        segments: {
            reserve: 'رزرو وقت',
            packages: "بسته‌ها",
            profile: "اطلاعات عمومی",
            medicalInfo: "اطلاعات پزشکی",
            factors: "فاکتور",
            wallet: "کیف‌پول"
        },
        session_wallet: {
            empty: {
                title: "!اعتبار یا بسته برای رزرو وقت جلسه وجود ندارد",
                action: "افزایش اعتبار"
            },
            general_session: "جلسات عمومی",
            therapy_session: "جلسه روانشناسی",
            action: "رزرو وقت"
        },
        select_cycle: {
            titleSection: "موضوع مشاوره",
            title: "موضوع",
            package: "بسته‌روانشناسی",
            duration: "45 دقیقه",
            description: "شرح مسئله",
            selectCycle: "انتخاب از موضوع مشاوره‌ی دیگر",
            action: "تایید و ادامه",
            notify: {
                selectCycle: "لطفا یک دوره درمان انتخاب کنید",
                addit: "دوره‌درمان اضافه شد"
            },
            modal: {
                modalTitle: "موضوع مشاوره",
                description: "موضوع خود را انتخاب کنید",
                select_title: "موضوع جدید خود را انتخاب کنید",
                add_cycle: "افزودن موضوع جدید",
                add_description: "افزودن توضیحات",
            },
            cycle_heading: {
                Individual: {
                    title: "فردی",
                    description: "برای خودم"
                },
                Couple: {
                    title: "روابط و زوج",
                    description: "برای من و همسرم"
                },
                family: {
                    title: "خانواده",
                    description: "برای خانواده‌ام"
                },
                kid: {
                    title: "کودک و نوجوان",
                    description: "برای فرزندم"
                },
                sex: {
                    title: "سکس تراپی",
                    description: "برای من و شریک عاطفی"
                },
                Psychiatry: {
                    title: "روانپزشکی",
                    description: "برای تجویز دارو"
                }
            }
        },
        download: "دانلود",
        description: "توضیحات",
        select_time: {
            title: "زمان‌های ترجیحی خود را انتخاب کنید",
            description: "چه ساعاتی در طول هفته برای برگزاری جلسه شما مناسب است؟",
            continue: "تایید و ادامه",
        },
        accept_request: {
            title: "درخواست شما ثبت شد",
            newUser: "بعد از هماهنگی با تراپیست، وقت شما رزرو خواهد شد.\n" +
                "              در ساعت آینده تیم پشتیبانی ما یک جلسه کوتاه برای شما تنظیم می‌کند. طی این جلسه بهترین تراپیست برای شما\n" +
                "مشخص\n" +
                "و پیشنهاد می‌شود",
            oldUser: "بعد از هماهنگی با تراپیست، وقت شما رزرو خواهد شد"
        },
        factors: {
            datatable: {
                date: "تاریخ",
                type: "نوع",
                state: "وضعیت",
                amount: "مبلغ"
            },
            factorState: {
                paid: "پرداخت شده",
                submit: "پیش‌فاکتور",
                canPay: "قابل پرداخت",
                partially_paid: "پیش‌پرداخت"
            },
            paymentState: {
                awaiting: "در انتظار بازگشت از بانک",
                paid: 'پرداخت شده',
                wallet_pending: "",
                failed: "لغو شده",
                debit_checkout: "پرداخت نشده",
                rejected: "لفو شده",
                pending: "درانتظار تایید"
            },
            paymentType: {
                wallet_debt_settlement: "بابت بدهی کیف پول",
                online: "آنلاین",
                wallet_payment: "کیف‌پول",
                offline: "آفلاین"
            }
        },
        product: "محصول",
        details: "جزئیات",
        factor: "فاکتور",
        close: "بستن",
        packageFactor: " : بسته‌های این فاکتور  ",
        paymentDetail: " : جزئیات پرداخت ",

        //ssssss

        account: 'داشبورد',
        nextLabel: 'بعدی',
        Paid: 'پرداخت شده',
        return: "بازگشت",
        confirm: 'تایید',
        confirmAndContinue: "تایید و ادامه",
        assignconsult: {
            successMessage: "پرداخت با موفقیت انجام شد",
            failedMessage: "پرداخت با شکست مواجه شد",
            code: "شماره پیگیری",
            sorry: 'متأسفیم',
            oldUserTexts: [
                'در سیستم پرداخت، یک مشکل فنی رخ داده است.',
                'نگران نباشید، برای تکمیل فرایند رزرو، به تیم پشتیبانی از طریق واتس اپ یا تلگرام پیام دهید.',
            ],
            newUserTexts: [
                'در سیستم پرداخت، یک مشکل فنی رخ داده است.',
                'نگران نباشید، برای تکمیل فرایند رزرو و هماهنگی جهت برگزاری جلسه پیش مشاوره به تیم پشتیبانی سیمیاروم از طریق واتس اپ یا تلگرام پیام دهید.',
            ]
        },
        sentEmailLink: 'ایمیل حامل لینک برای شما ارسال شد',
        dontHaveAccount: 'حساب کاربری ندارید؟',
        phoneLabel: 'تلفن همراه',
        matchMaking: {
            pleaseAnswer: 'لطفا به سوال پاسخ دهید.',
            lastMessage: [
                'تقریبا',
                ' از افرادی که پاسخ‌هایی مشابه شما داده‌اند، به موارد زیر در جلسه پیش مشاوره و جلسات ابتدایی تراپی (مشاوره روانشناسی) اشاره کرده‌اند:\n',
                ' در ادامه، شما می‌توانید پس از تکمیل فرآیند ثبت نام، در جلسه پیش مشاوره، تمامی شرایط و نیازهای خود را با متخصص روانشناسی سیمیاروم در میان بگذارید.',
            ],
            chooseFieldQuestion: 'موضوع مشاوره خود را مشخص کنید',
            buttons: {
                submit: 'تایید',
                firstStep: 'اولین قدم'
            },
            registerForm: {
                registerByGoogle: 'ثبت نام با حساب گوگل',
                registerByEmail: 'ثبت نام با ایمیل'
            },
            primaryResult: {
                1: [
                    '1. به‌ هم ریختگی در روتین خواب و اشتها',
                    '2. احساس خستگی ناشی از مداومت استرس',
                    '3. مشکل در مدیریت هیجان به ویژه خشم',
                    '4.  ابتلا به مشکلات قلبی-عروقی',
                ],
                2: [
                    '1. اختلال در مدیریت هیجان به ویژه خشم',
                    '2. افزایش نشانه های اضطرابی',
                    '3. چالش در ارتباطات بین فردی و زوجی',
                ],
                3: [
                    '1. مشکل در توجه و تمرکز',
                    '2. اهمال کاری',
                    '3. اختلالات یادگیری و حافظه',
                    '4. چالش در ارتباطات بین فردی'
                ],
                4: [
                    '1. اختلالات اضطرابی',
                    '2. اهمال‌کاری',
                    '3. مشکل در توجه و تمرکز',
                ],
                5: [
                    '1. اختلالات اضطرابی',
                    '2. چالش در ارتباطات بین فردی',
                    '3. کمال‌گرایی',
                    '4. اهمکال کاری',
                ],
                6: [
                    '1.تجربه خلق پایین و افسردگی',
                    '2. مشکل در مدیریت موقعیت‌های استرس‌زا',
                    '3. چالش در هویت و دلبستگی',
                ],
                7: [
                    '1. مواجهه با مشکلات در روابط عاطفی',
                    '2. افزایش نشانه های اضطرابی',
                ],
                8: [
                    '1. افزایش نشانه های اضطرابی',
                    '2. نشخوار و وسواس‌های فکری',
                    '3. احتمال چالش با اطرافیان و خانواده',
                ],
                9: [
                    '1. تجربه خلق پایین و افسردگی',
                    '2. مشکلات تفاوت بین فرهنگی و بین نسلی',
                    '3. مشکلات جنسی',
                ],
                10: [
                    '1. اختلالات اضطرابی',
                    '2. نشخوار و وسواس‌های فکری',
                    '3. مشکلات زوجی',
                ],
                11: [
                    '1.ایجاد مشکل در کنترل هیجان',
                    '2. سوء مصرف مواد',
                    '3. چالش در روابط بین فردی',
                ],
                12: [
                    '1. بروز مشکل در روابط بین فردی',
                    '2. مشکلات شغلی-تحصیلی',
                    '3.  اهمال‌کاری',
                    '4. افکار خودکشی',
                ],
                13: [
                    '1. ابتلا به اختلالات افسردگی',
                    '2. نشخوار فکری و نگرانی‌ها در مورد تغییرات فیزیکی بدن',
                    '3. اختلالات اضطرابی',
                ],
                14: [
                    '1. اختلالات اضطرابی از جمله حمله پنیک',
                    '2. خلق پایین و افسردگی',
                    '3. چالش در روابط بین فردی',
                    '4. مشکلات در حوزه شغلی-تحصیلی',
                ]
            },
            profile: 'پروفایل',
            selectTherapist: 'انتخاب تراپیست',
            register: 'ثبت‌نام',
            description: 'توضیحات',
            hintTitle: 'سپاس از همراهی شما در پاسخگویی به سوالات',
            hintDescription: [
                'اطلاعات تکمیلی در جلسه پیش مشاوره و جلسات درمانی به صورت کامل دریافت و بررسی خواهند شد',
                'از شما می‌خواهیم به چند سوال عمومی نیز برای کمک بیشتر به تحلیل داده‌ها، پاسخ دهید.'
            ],
        },
        signUpLabel: "ثبت‌نام",
        accountRecoveryCaption: 'برای بازیابی حساب کاربری، ایمیل خود را وارد کنید.',
        accountRecovery: 'بازیابی حساب کاربری',
        emailErrRequired: 'پر کردن  فیلد ایمیل الزامی است',
        passwordLabel: 'کلمه عبور',
        passwordConfirmationLabel: 'تکرار کلمه عبور',
        emailLabel: 'ایمیل',
        register: "ثبت نام",
        loginComponent: {
            testLogin: "برای دریافت تفسیر تست خود با حساب کاربری خود وارد شوید یا ثبت‌نام کنید",
            login: "ورود",
            title: "به سیمیاروم خوش آمدید",
            subTitle: "برای خرید لطفا ابتدا وارد شوید",
            form: [
                'آدرس ایمیل',
                'کلمه عبور'
            ],
            registerLabel: "ثبت نام نکرده اید؟",
            noAccount: "حساب کاربری ندارم!",
            haveAccount: "حساب کاربری دارم!",
            registerAction: "ثبت نام کنید",
            resetPassword: "رمز عبور را فراموش کرده اید؟",
            forgetPassword: {
                title: "بازیابی حساب کاربری",
                subTitle: "لینک ساخت کلمه عبور جدید به ایمیل شما ارسال خواهد شد.",
                action: "دریافت‌لینک",
                loginLabel: "حساب کاربری دارید؟",
                loginAction: "وارد شوید",
            },
            checkEmail: "ایمیل خود را چک کنید",
        },
        onboarding: {
            country: {
                title: "لطفا کشور محل زندگی خود را انتخاب کنید",
                subTitle: "این سوال به جهت پیشنهاد زمان‌های مناسب برای برگزاری جلسات شما پرسیده می‌شود."
            },
            startStep: {
                title: "مشاوره روانشناسی سیمیاروم چگونه کار می کند؟",
                reasons: [
                    '1 - یک نام کاربری و رمز عبور خصوصی برای دسترسی به سیستم ایجاد کنید.',
                    '2 - اطلاعات پرداخت را وارد و مبلغ پکیج مورد نظر را پرداخت نمایید.',
                    '3 - یک جلسه پیش مشاوره برای شناخت بیشتر و تعیین درمانگر شما برگزار می‌شود.',
                    '4 - بر اساس نتیجه جلسه پیش مشاوره، ما شما را با یک درمانگر متخصص هماهنگ می‌کنیم.'
                ]
            },
            stepTwo: {
                title: "چه چیزی باعث شد که به فکر مشاوره باشید؟",
                why: {
                    1: "احساس افسردگی کرده ام",
                    2: "احساس اضطراب یا مستاصل شدن (پریشان حالی و کلافه بودن) می کنم",
                    3: "خلق و خوی من با عملکرد شغلی / درسی ام تداخل دارد",
                    4: "با ایجاد یا حفظ روابط چالش دارم",
                    5: "نمی توانم هدف و معنایی در زندگی ام پیدا کنم",
                    6: "غصه میخورم",
                    7: "ضربه (چالش) روحی را تجربه کرده ام",
                    8: "باید درباره یک چالش یا موقعیت خاص صحبت کنم",
                    9: "می خواهم اعتماد به نفسم را تقویت کرده یا بدست بیاورم",
                    10: "می خواهم خودم را بهبود بخشم اما نمی دانم از کجا شروع کنم",
                    11: "استفاده از خدمات مشاوره به من توصیه شده است (دوست، خانواده، دکتر)",
                    12: "سایر",
                }
            },
            stepThree: {
                title: "انتظارات شما از درمانگرتان چیست؟ درمانگری که…",
                exception: {
                    1: ' شنونده خوبی باشد',
                    2: ' تجربه سکونت در خارج از کشور را داشته باشد',
                    3: ' گذشته من را کاوش می کند',
                    4: ' به من مهارت های جدیدی می آموزد',
                    5: ' باورهای من را به چالش می کشد',
                    6: ' به من تکلیف می دهد',
                    7: ' مرا برای تعیین اهداف راهنمایی می کند',
                    8: ' فعالانه با من در ارتباط بوده و مسائل من را چک می کند',
                    9: ' سایر',
                    10: ' من نمی دانم',
                }
            },
            hint: {
                title: "مشاوره روانشناسی و طول دروه درمان من چند جلسه خواهد بود؟",
                hints: [
                    'حداقل زمانی که شما نیاز دارید تا از اثر مشاوره در زندگی خود آگاه شوید، 5 جلسه است.',
                    'طبق آمار جهانی،',
                    'حداقل میانگین دوره درمان',
                    'روانشناسی 13 تا 16 جلسه بوده،',
                    'اما تعداد این جلسات براساس نوع روش درمان و میزان شدت مسائل ',
                    'شما می‌تواند بیشتر یا کمتر باشد.',
                    '                      بخاطر داشته باشید مسائلی که مدت زیادی شما را دچار چالش کرده است نیازمند زمان جهت بهبود می‌باشد.'
                ]
            }
        },
        addCycleModal: {
            add: "افزودن",
            title: "موضوع مشاوره و شرح مشکل خود را وارد نمایید",
            countChar: "حداقل 20 کاراکتر",
            categories: [
                'فردی',
                'روابط و زوج',
                'خانواده',
                'کودک و نوجوان',
                'سکس تراپی',
                'روانپزشکی',
            ],
            categorySession: "موضوع مشاوره",
            problemDescription: "شرح مساله",
            problemDescriptionPlaceHolder: "به اختصار موضوع مورد نظر خود را برای مشاور شرح دهید.",
            selectCycle: {
                title: "انتخاب دوره مشاوره",
                selecting: "لطفا دوره مشاوره خود را انتخاب نمایید",
                confirm: "تایید درخواست جلسه",
                action: "افزودن دوره جدید",
                issueSession: "موضوع دوره :",
                totalSession: "تعداد کل جلسات",
                request: "درخواست جلسه",
            },
        },
        loginLabel: 'ورود',
        emailErrValid: 'یک ایمیل معتبر وارد کنید',
        passwordErrRequired: 'پر کردن  فیلد کلمه عبور الزامی است',
        onBoarding: {

            titleLogin: "ثبت نام کاربر جدید",
            continue: 'ادامه',
            next: "بعدی",
            prev: "قبلی",
            selectedPackage: 'انتخاب بسته',
            stepOne: {
                title: 'در مسیر یک حال بهتر',
                subTitle: "پاسخ به چند سوال کوتاه جهت آشنایی بیشتر مشاور با شما",
                start: "شروع",
                skip: "تمایلی  ندارم"
            },
            stepTwo: {
                title: "موضوع مشاوره خود را انتخاب کنید",
                subtitle: "در صورت نیاز می توانید اطلاعات خود را در پنل کاربری تغییر دهید.",
                cycles: [
                    'فردی',
                    ' رابطه و زوج',
                    'کودک و نوجوان',
                    'خانواده',
                    'روانپزشکی',
                    'سکس تراپی'
                ],
            },
            stepThree: {
                title: "جنسیت خود را انتخاب کنید",
                gender: [
                    'مرد',
                    'زن',
                    'موارد دیگر'
                ]
            },
            stepFour: {
                title: 'چند سال دارید؟',
                agePeriods: [
                    'کمتر از 18',
                    'بیشتر از 60'
                ]
            },
            history: "آیا سابقه استفاده از خدمات روانشناسی را دارید؟ ",
            stepFive: {
                title: "وضعیت تاهل شما چگونه است؟",
                relationships: [
                    'مجرد',
                    'متاهل',
                    'شریک عاطفی',
                    'موارد دیگر'
                ]
            },
            loginWithDifferentWay: "ورود یا ثبت نام با روش دیگر",
            loginItems: [
                'حساب گوگل',
                'حساب فیسبوک'
            ],
            terms: "شرایط و قوانین ",
            wizardLogin: [
                'ثبت نام',
                'ورود'
            ],
            wizardStepIndicator: {
                info: "مشخصات",
                register: "ثبت نام",
                pay: "پرداخت",
                selectConsulter: "انتخاب زمان و مشاور"
            },
            whoSelectDoctor: {
                title: "چگونه تراپیست شما انتخاب شود؟",
                items: [
                    'خودم انتخاب میکنم',
                    'متخصصان سیمیاروم انتخاب کنند',
                ]
            },
            selectDoctor: {
                searchPlaceHolder: 'جستجوی نام مشاور',
                labelCategory: 'تخصص',
                all: 'همه'
            },
            selectTime: {
                title: "زمان‌های ترجیحی خود را انتخاب کنید",
                subTitle: " چه ساعاتی در طول هفته برای برگزاری جلسه شما مناسب است؟"
            },
            testimonial: {
                sweden: {
                    title: 'سیما از سوئد',
                    desc: "من خیلی خوشحالم که پیداتون کردم و تونستم تو شرایطی که به کسی نمی‌تونستم اعتماد کنم، از شما و آقای دکتر کمک گرفتم. الان حالم خیلی بهتره و واقعا ازتون ممنونم."
                },
                usa: {
                    title: 'ابراهیم از آمریکا',
                    desc: "دید من به زندگی بعد از تراپیم عوض شد. خیلی چیزهایی که زجر آور بودن دیگه نیستن و این به‌خاطر نگاهی بود که تو تراپی به دست آوردم. ممنون از شما و مشاوراتون."
                },
                germany: {
                    title: 'دلناز از آلمان',
                    desc: "    من تجربه تراپی بد رو داشتم و می‌دونم چقدر می‌تونه به آدم بیشتر آسیب بزنه و از شروع مجدد می‌ترسیدم. تا این که دوستم شما رو معرفی کرد و نمی‌دونید چقدر ممنونتونم به خاطر حال خوب الانم."

                },
                usaTwo: {
                    title: 'شادی از آمریکا',
                    desc: "خیلی ممنون از سیمیاروم و مشاوراش. من واقعا این سیستم رو هرجایی ندیدم که وقتی ببینن با مشاورت به نتیجه دلخواه نمی‌رسی، مشاور رو تغییر بدن تا بهترین نتیجه رو بگیری‌."
                },
                canada: {
                    title: 'محمدحسین از کانادا',
                    desc: '    پلتفرمی نبود که من رزومه مشاوراش رو واسه پیدا کردن مشاور نگشته باشم. تا این که به سایت شما رسیدم و دیدم هر مشاوری که هست هم رزومه معتبری داره، هم تجربه‌ زیاد. به خاطر همین به شما اعتماد کردم و از این اعتماد هم راضی‌ام.'
                },
                netherlands: {
                    title: "آرتمیس از هلند",
                    desc: '    من واقعا مشکل خاصی نداشتم؛ اما بعد مهاجرتم احساس می‌کردم یه چیزی کمه و اعتماد به نفسم پایین اومده بود. به خاطر همین دنبال یه تراپیست خوب گشتم و سر از سایت شما درآوردم.  الان همه چی عالیه و من از مشاورم و سیمیاروم خیلی ممنونم.'
                }
            },
            priceBox: {
                select: 'انتخاب مدل مشاوره',
                title: ' پکیج مورد نظرتان را انتخاب نمایید ',
                subtitle: 'شما می‌توانید مناسب ترین مدل مشاوره را برای مشاوره انتخاب کنید',
                labelPopular: 'محبوب ترین',
                labelBestPrice: 'بهترین‌قیمت',
                hint: ' ضمانت بی‌قید و شرط بازگشت وجه درصورت عدم رضایت',
                emptyBasket: 'هنوز بسته‌ای انتخاب نکرده‌اید'

            }
        },
        signupComponent: {
            title: "ایجاد حساب",
            subTitle: "لطفا اطلاعات زیر را وارد نمایید",
            form: [
                "تلفن همراه (با کد کشور)"
            ],
            terms: "شرایط و ضوابط",
            accept: 'را خوانده و میپذیرم.',
            inviter: "کد معرف شما :",
            action: "ثبت نام",
            loginLabel: " حساب کاربری دارید؟",
            loginAction: "وارد شوید",
            phone: "تلفن همراه",
            registerAction: "ثبت‌نام",
            acceptTerms: 'شرایط و قوانین را قبول دارم',
            loginActionOnBoarding: "ورود با حساب کاربری",
        },
        logout: 'خروج از حساب کاربری',
        showAccount: "حساب کاربری",
        $vuetify: {
            noDataText: 'مورد وجود ندارد'
        },
        gender: {
            female: 'زن',
            FEMALE: 'زن',
            male: 'مرد',
            MALE: 'مرد',
            OTHER: 'موارد دیگر',
            other: 'موارد دیگر',
        },
        relationships: {
            single: 'مجرد',
            married: 'متاهل',
            inrel: 'شریک عاطفی',
            other: 'موارد دیگر',
            divorced: "طلاق‌گرفته",
            partner: 'موارد دیگر',
            rel: "شریک عاطفی",
            otherState: "موارد دیگر"
        },
        errors: {
            fillIsRequired: 'پر کردن این فیلد الزامی است',
            passwordLengthError: "رمز عبور باید بیش از ۸ کارکتر باشد",
            emailErrValid: "ایمیل را با ساختار مناسب وارد کنید",
            phoneNumberError: "شماره تماس را با ساختار درست وارد کنید."
        },
        Notify: {
            without_cycle: "برای ثبت درخواست جلسه، ابتدا اطلاعات خود را کامل کنید",
            session_request: "درخواست جلسه با موفقیت ثبت شد",
            medicalEdit: "با موفقیت اضافه شد",
            requiredChat: "لطفا متن پیام خود را وارد کنید",
            loginSuccess: "با موفقیت وارد شدید",
            loginFailed: "نام کاربری یا کلمه عبور شما اشتباه می باشد",
            doctorTime: [
                'شما نمیتوانید بیشتر از',
                ' ساعت انتخاب کنید',
            ],
            successfully: "با موفقیت به ثبت رسید",
            successfullyV2: "عملیات با موفقیت انجام شد.",
            successfullyWithRedirect: "با موفقیت به ثبت رسید، به صفحه داشبورد منتقل میشوید",
            successfullyRequest: 'درخواست شما با موفقیت به ثبت رسید',
            successDateTime: "اطلاعات شما با موفقیت ثبت شد، تا 24 ساعت آینده با شما تماس خواهیم گرفت",
            changePassword: "کلمه عبور با موفقیت تغییر یافت",
            packageComponent: "لطفا حداقل یک ارز پرداختی برای بسته انتخاب نمایید",
            changeStatus: 'لطفا یکی از زمانهای مشاور را انتخاب نمایید',
            DateTime: {
                selectDateFirst: "لطفا ابتدا تاریخ مورد نظر را انتخاب نمایید",
                selectDay: "لطفا حداقل یک روز و زمانهای آن را انتخاب نمایید",
                success: "زمانهای پیشنهادی شما با موفقیت به ثبت رسیدند",
            },
            clientSessionRequest: 'لطفا یکی از زمانهای مشاور را انتخاب نمایید',
            signupComponent: {
                phoneNumberError: "شماره تلفن معتبر نیست.",
                success: "ثبت نام با موفقیت انجام شد",
                emailError: "ایمیل وارد شده تکراری است",
                passwordError: "کلمه عبور را با دقت وارد کنید",
                passwordLengthError: "حداقل طول کلمه عبور 8 کاراکتر می باشد",
                rePasswordRequired: "پر کردن فیلد تکرار کلمه عبور الزامیست",
                rePasswordCheck: 'کلمه عبور و تکرار آن یکسان نمی باشند',
                phoneRequired: "وارد کردن تلفن الزامیست",
                countrySelected: "کد کشور را انتخاب نمایید",
                passwordRequired: "پر کردن فیلد تکرار کلمه عبور الزامیست",
                notSamePassword: 'کلمه عبور و تکرار آن یکسان نمی باشند',
            },
            login: {
                password: {
                    passwordRequired: "وارد کردن کلمه عبور الزامیست",
                    minLength: "حداقل طول کلمه عبور 8 کاراکتر می باشد"
                }
            },
            acceptTerms: "موافقت با شرایط و ضوابط الزامیست.",
            calender: {
                successDelete: "با موفقیت حذف شد"
            },

            error: 'خطایی رخ داده است',
            connectionError: "لطفا اینترنت خود را بررسی کنید",
            addNewCycle: {
                oneSession: "حداقل تعداد جلسات 1 می باشد.",
                notEnough: "تعداد جلسات شما کافی نیست.",
            },
            clientDashboard: {
                selectTime: "لطفا یکی از زمانهای مشاور را انتخاب نمایید",
                selectOne: "لطفا یک گزینه را انتخاب نمایید",
                success: "زمان های شما با موفقیت به ثبت رسیدند",
                selectTimespan: "لطفا حداقل یک بازه را انتخاب نمایید"
            },
            ExperssComponent: {
                selectOne: "لطفا یکی از زمانهای مشاور را انتخاب نمایید",
                successSelect: "زمانهای پیشنهادی شما با موفقیت به ثبت رسیدند"
            },
            CartComponent: {
                enterCode: "کد تخفیف را وارد نمایید",
                needLogin: "برای خرید ابتدا وارد شوید یا ثبت نام کنید"
            },
            cycle: {
                needPackage: "شما بسته ای برای درخواست جلسه ندارید، ابتدا خرید کنید"
            },
            sessionCountRules: {
                countRequired: "تعداد جلسات را وارد نمایید",
                minCount: "تعداد جلسات حداقل باید 1 باشد",
                buyError: "تعداد جلسات درخواستی باید از جلسات خریداری شده شما کمتر باشد"
            },
            successCopy: 'کپی شد'
        },
        currency: {
            longRial: 'تومان',
            rial: 'T',
            dollar: '$',
            dollarCanada: 'Dollar canada',
            dollarAustralia: 'Dollar australia',
            Euro: '€',
            pound: 'pound',
            dollarLong: "دلار",
            dollarCanadaLong: "دلار کانادا",
            dollarAustraliaLong: "دلار استرالیا",
            EuroLong: "یورو",
            poundLong: "پوند",
            try: 'لیر',
        },
        welcome: 'به پنل کاربری خود خوش آمدید',
        routes: {
            dashboard: 'سیمیاروم؛ مشاوره آنلاین روانشناسی ویژه ایرانیان خارج از کشور',
            sessions: 'جلسات',
            wallet: 'تراکنش‌ها/کیف پول',
            index: 'صفحه اصلی',
            packages: 'بسته‌ها',
            messenger: "اتاق‌گفتگو",
            profile: 'پرونده من',
            tasks: 'آزمون‌ها/تمرین‌ها',
            userInfo: 'مشخصات و تنظیمات',
            basket: 'سبد‌خرید',
            factor: 'فاکتور',
            factors: "فاکتور‌ها",
            personal_profile: "اطلاعات شخصی",
            roots_in_soil: 'ریشه در خاک',
            tests: "تست‌ها",
            test_history: "تاریخچه‌ تست‌ها",
            chatGpt: 'هوش مصنوعی',
            invitees: 'لیست دعوت‌ها'
        },
        wallet: {
            charge: 'واریز',
            use: 'برداشت',
            balance: 'موجودی',
            wallet: 'کیف‌پول',
            transaction: 'تراکنش‌ها',
            title: "سبد خرید",
        },
        basket: {
            title: "بسته‌(های) انتخاب شده",
            addGiftCode: "افزودن کد هدیه",
            factor: "مبلغ",
            discountCode: "کد‌تخفیف",
            off: "تخفیف و کد هدیه",
            wallet: "کیف پول",
            finalPrice: "مبلغ نهایی",
            onlinePay: "پرداخت آنلاین",
            offlinePay: "پرداخت آفلاین",
            datatable: {
                title: "نوع بسته",
                qty: "تعداد",
                price: "قیمت‌واحد",
                totalAmount: "قیمت‌واحد"
            }
        },
        singleFactor: {
            title: "بسته‌(های) انتخاب شده",
            addGiftCode: "افزودن کد هدیه",
            factor: "فاکتـــور",
            discountCode: "کد‌تخفیف",
            off: "تخفیف و کد هدیه",
            wallet: "کیف پول",
            remaining: "باقی مانده",
            finalPrice: "مبلغ نهایی",
            onlinePay: "پرداخت آنلاین",
            offlinePay: "پرداخت آفلاین",
            payments: "پرداخت‌ها",
            datatable: {
                title: "نوع بسته",
                qty: "تعداد",
                price: "قیمت‌واحد",
                totalAmount: "قیمت‌کل",
                off: "تخفیف"
            }
        },
        socialLogin: {
            enterNumber: "لطفا شماره تماس خود را وارد کنید"
        },

        dashboard: {
            modal: {
                title: "اطلاعات شما با موفقیت ثبت شد.",
                desc: "",
                action: "تایید"
            },
            chat: {
                action: "شروع گفتگو",
                title: "چت با پشتیبان",
                placeholder: "چیزی بنویسید"
            },
            wallet_status: {
                title: "جلسات باقی مانده از بسته مشاوره:",
                subtitle: " برای خرید بسته از اینجا اقدام کنید ",
                action: " خرید بسته"
            },
            session_status: {
                title: "وضعیت جلسه بعدی شما",
                subtitle: " کیف جلسات شما خالی است. لطفا بسته خریداری کنید ",
                action: "خرید بسته"
            },
            sessions: {
                title: ' جلسات پیش رو ',
                subtitle: ' جلساتی که رزرو وقت شده ',
                action: " جزئیات ",

            },
            walletSession: {
                title: 'مانده اعتبار جلسات شما',
                subtitle: ' تعداد جلساتی که از اعتبار باقی مانده ',
                action: 'خرید بسته'
            },
            wallet: {
                title: 'تراکنش‌ها/کیف پول',
                subTitle: 'افزودن کد هدیه'
            },
            inviteFriends: {
                title: ' حال خوب به دوستت هدیه بده !',
                subtitle: " ‏10 دلار اعتبار هدیه ",
                action: 'معرفی به دوستان'
            },
            tests: {
                title: 'تست‌های رایگان'
            }
        },
        CartComponent: {
            PreSessionModal: {
                title: 'پیش‌مشاوره',
                continue: "تایید و ادامه",
                text: {
                    leadUser: [
                        'از آنجایی که انتخاب بهترین مشاور، متناسب با موضوع درخواستی شما، یکی از مهمترین عوامل در نتیجه گیری از جلسات تراپی است، سیمیاروم مانند بسیاری از مراکز مشاوره تخصصی در دنیا، با برگزاری یک جلسه 15 دقیقه ای پیش مشاوره، تمامی انتظارات شما از مشاور و  جلسات تراپی شنیده شده و بهترین مشاور متخصص به شما معرفی می شود.',
                        ' این جلسه رایگان خواهد بود و طی ۲۴ ساعت آینده همکاران ما برای تعیین زمان مناسب با شما تماس میگیرند.'
                    ],
                    oldUser: [
                        'جهت دریافت لینک پرداخت لطفا به تیم پشتیبانی پیام دهید.',
                        'تماس با تیم پشتیبانی'
                    ]
                },
                action: 'متوجه شدم'
            },
            payByWallet: "کسر از کیف پول",
            clearingWallet: 'تسویه کیف پول',
            invoiceItems: {
                total: "جمع کل",
                offer: "تخفیف",
                finalPrice: "مبلغ نهایی"
            },
            title: "بسته های انتخاب شده",
            price: "مبلغ",
            headersTable: [
                'آیتم',
                'قیمت واحد',
                'تعداد',
                'قیمت کل',
                'حذف'
            ],
        }
    }
}
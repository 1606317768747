<template>
  <div class="primary fill-height d-flex flex-column justify-center">
    <div class="loaderArea" v-if="loader">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div class="formArea">
      <div class="headerForm">
        جلسه پیش‌مشاوره
      </div>
      <div class="messengerContent">
        <div class="getInitMessage mt-5 " v-if="state == 'get-init-info'">
          <div class="font-weight-bold">
            اطلاعات اولیه
          </div>
          <v-form ref="init">
            <v-row class="mt-2">
              <v-col cols="12" lg="3">
                <v-text-field dense v-model="init_message.name" outlined label="نام و نام خانوداگی"></v-text-field>
              </v-col>
              <v-col cols="12" lg="3">
                <v-text-field dense outlined label="سن" v-model="init_message.age"></v-text-field>
              </v-col>
              <v-col cols="12" lg="12">
                <v-textarea label="شرح کوتاه از مشکل" outlined dense v-model="init_message.description">

                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-btn color="primary" elevation="0" @click="sendStartFlowRequest">
            شروع فرایند پیش‌مشاوره
          </v-btn>

        </div>
        <div class="messagesArea" v-if="state == 'session-in-proccess'">
          <div v-for="(message , i ) in messages" :key="i" class="d-flex mb-5"
               :class="message.sender == 'chatgpt' ? 'justify-start' : 'justify-end' ">
            <div class="messageItem chatGptSender " v-if="message.sender == 'chatgpt'">
              {{ loadQuestion(message.message) }}
            </div>
            <div class="messageItem clientSender" :key="i" v-if="message.sender == 'client'">
              {{ message.message }}
            </div>
          </div>
        </div>
      </div>
      <div class="actionArea">
        <v-form ref="answer">
          <v-text-field v-model="answer" :rules="requiredRules" label="پاسخ" outlined dense class="mt-4"
                        v-if="state == 'session-in-proccess'">
            <template v-slot:append>
              <v-btn small color="primary" style="margin-top: -2px" elevation="0" @click="answerQuestion">
                ارسال
              </v-btn>
            </template>
          </v-text-field>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      state: 'get-init-info',
      init_message: {},
      messages: [],
      thread_id: null,
      messagesCount: 0,
      answer: null,
      loader: false
    }
  },
  methods: {
    loadQuestion(message) {
      return JSON.parse(message).question
    },
    sendStartFlowRequest() {
      if (this.$refs.init.validate()) {
        this.loader = true
        let message = 'سلام '
        message += this.init_message.name + ' هستم. '
        message += this.init_message.age + 'ساله هستم.'
        message += this.init_message.description
        axios.post('https://psychepro.simiaroom.com/api/multi-assistant/start-flow', {
          flow_id: 1,
          init_message: message,
        }).then((resp) => {
          this.state = 'session-in-proccess'
          this.thread_id = resp.data.id
          setTimeout(() => {
            this.getListMessages()
          }, 3000)
        }).finally(() => {
        })
      }
    },
    getListMessages() {
      this.loader = true
      axios.get(`https://psychepro.simiaroom.com/api/multi-assistant/${this.thread_id}/get-thread-messages`).then((resp) => {
        this.messagesCount = this.messages.length
        let newMessages = resp.data.reverse()
        let lastIndex = resp.data.length - 1
        if (resp.data.length == 0 || resp.data[lastIndex].sender == 'client') {
          this.messages = newMessages
          setTimeout(() => {
            this.getListMessages()
          }, 3000)
        } else {
          this.messages = newMessages
          this.loader = false
        }
      })
    },
    answerQuestion() {
      if (this.$refs.answer.validate()) {
        this.loader = true
        axios.post('https://psychepro.simiaroom.com/api/multi-assistant/answer-question', {
          thread_id: this.thread_id,
          message: this.answer
        }).then(() => {
          this.getListMessages()
          this.answer = null
        })
      }
    }
  }
}
</script>

<style scoped>
.loaderArea {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.formArea {
  width: 50%;
  margin: 0px auto;
  background: white;
  border-radius: 8px;
  padding: 10px;
}

.getInitMessage {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  border-radius: 8px;
}

.headerForm {
  font-family: bold !important;
  text-align: center;
  font-size: 15pt;
  border-bottom: 1px solid #DDDDDD;
  padding: 10px;
}

.chatGptSender {
  background: rgba(60, 190, 139, 0.5);
  padding: 10px;
  width: 80%;
  border-radius: 10px 0px 0px 10px;
  line-height: 25pt;
}

.clientSender {
  background: rgba(128, 128, 128, 0.5);
  padding: 10px;
  width: 80%;
  border-radius: 0px 10px 10px 0px;
  line-height: 25pt;

}

.messengerContent {
  height: 500px;
  padding: 10px;
  overflow-y: auto;
}

.actionArea {
  border-top: 1px solid #DDDDDD;
  padding: 10px;
}
</style>
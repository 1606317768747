<template>
  <div class="fill-height d-flex flex-column justify-center align-center">
    <div>
      <div class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div class="text-center mt-3">
        درحال‌ آماده سازی
      </div>
    </div>
  </div>
</template>

<script>
import EventsMixins from "@/mixins/EventsMixins";

export default {
  mixins: [EventsMixins],
  mounted() {
    if (this.$route.query.token) {
      this.$store.commit('SET_TOKEN', {
        key: 'token',
        data: this.$route.query.token
      })
      localStorage.setItem('token', this.$route.query.token)
      this.$store.commit('SET_LOGIN')

      this.requesting('auth', 'getProfile').then(() => {
        this.WebengageSetUserLoggedIn(this.$store.getters.getProfile.id)

        if (this.$route.query.callback && this.$route.query.callback == "onboarding_presession"){
          this.$router.push({name: "preorder", params: {orderId: 197}})
        }

        else {
        this.$router.push({name: this.$route.query.callback, params: {lang: 'fa'}})
        }
      })
    }

    else {
      this.$router.push({name: 'login', params: {lang: 'fa'}})
    }
  }
}
</script>

<style scoped>

</style>

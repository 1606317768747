export default [
    {
        path: 'panel',
        name: 'panelLayout',
        redirect: '/',
        component: () => import('../../layouts/PanelLayout'),
        meta: {
            auth: true
        },
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('../../views/panel/DashboardView'),
                meta: {
                    routeKey: 'dashboard',
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                name: 'basket',
                path: 'basket',
                component: () => import('../../views/panel/BasketView'),
                meta: {
                    routeKey: 'basket',
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                path: 'sessions',
                name: 'sessions',
                component: () => import('../../views/panel/SessionsView'),
                meta: {
                    routeKey: 'sessions',
                    // nav: {
                    //     filter: true
                    // },
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                path: 'sessions/request',
                name: 'sessions-request',
                component: () => import('../../views/panel/SessionsView'),
                meta: {
                    routeKey: 'sessions',
                    // nav: {
                    //     filter: true
                    // },
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                path: 'factors',
                name: "wallet",
                component: () => import('../../views/panel/WalletView'),
                meta: {
                    routeKey: 'factors',
                    // patentRoute: 'index',
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                path: 'user-onboarding',
                name: 'userOnboarding',
                component: () => import('../../views/panel/UserOnboarding'),
                meta: {
                    routeKey: 'userInfo',
                    patentRoute: 'index',
                    auth: true
                }
            },
            {
                path: 'packages',
                name: "packages",
                component: () => import('../../views/panel/PackagesView'),
                meta: {
                    routeKey: 'packages',
                    auth: true,
                    // checkRequiredFiled: true,
                }
            },
            // {
            //     path: 'messenger',
            //     name: "messenger",
            //     component: () => import('../../views/panel/MessengerView'),
            //     meta: {
            //         routeKey: 'messenger',
            //         auth: true,
            //     }
            // },
            // {
            //     path: 'messenger/:userId',
            //     name: "chat",
            //     component: () => import('../../views/panel/MessengerView'),
            //     meta: {
            //         routeKey: 'messenger',
            //         auth: true,
            //     }
            // },
            // {
            //     path: 'profile',
            //     name: "profile",
            //     component: () => import('../../views/panel/ProfileView'),
            //     meta: {
            //         routeKey: 'profile',
            //         auth: true,
            //     }
            // },
            {
                path: "roots-in-soil",
                name: "roots-in-soil",
                component: () => import('../../views/indexes/rootsInSoil'),
                meta: {
                    routeKey: 'roots_in_soil',

                }
            },
            {
                path: "tests",
                name: "tests",
                component: () => import('../../components/Dashboard/Layouts/TestUserDashboard'),
                meta: {
                    routeKey: 'tests',

                }
            },
            {
                path: "test/:testID",
                name: "test_history",
                component: () => import('../../views/panel/TestsHistoryView'),
                meta: {
                    routeKey: 'test_history',

                }
            },
            {
                path: 'setting',
                name: "setting",
                component: () => import('../../views/panel/NewProfile'),
                meta: {
                    routeKey: 'personal_profile',
                    auth: true,
                }
            },
            {
                path: 'tasks',
                name: "tasks",
                component: () => import('../../views/panel/TasksView'),
                meta: {
                    routeKey: 'tasks',
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                path: 'factor/:factorID',
                name: 'factor',
                component: () => import('../../views/panel/SingleFactorView'),
                meta: {
                    routeKey: 'factor',
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                path: 'mental-ai-tool',
                name: 'chatGpt',
                component: () => import('../../views/panel/MentalAiTool'),
                meta: {
                    routeKey: 'chatGpt',
                    auth: true,
                    checkRequiredFiled: true,
                }
            },
            {
                path: 'invitees',
                name: 'invitees',
                component: () => import('../../views/panel/Invitess'),
                meta: {
                    routeKey: 'invitees',
                    auth: true,
                    checkRequiredFiled: true,
                }
            }
        ]
    }
]